export default function usePagination() {
  const route = useRoute()

  const page = computed(() => {
    const page = Number(route.query.page)
    if (isNaN(page)) return 1
    return page
  })

  const getPageLink = (page: number) => ({
    query: {
      ...route.query,
      page
    }
  })

  const nextPageLink = computed(() => getPageLink(page.value + 1))
  const previousPageLink = computed(() => getPageLink(page.value - 1))

  return {
    page,
    getPageLink,
    nextPageLink,
    previousPageLink
  }
}

export type PaginationState = ReturnType<typeof usePagination>
